html,
body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.u-row {
  display: flex;
  flex-direction: row;
}

.u-row > * {
  margin-right: 12px !important;
}

.u-row > *:last-child {
  margin-right: 0 !important;
}
